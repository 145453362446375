<template>
	<div>
		<a-layout>
			<a-layout-sider style="background: #FFFFFF;overflow: hidden;min-height: calc(100vh - 100px); " width="200px">
				<div style="padding: 20px 20px;">
					<div v-for="(item,index) in menus" :key="index" @click="goToUrl(index)" class="second-menu-item " :class="{on:getSelectKey.indexOf(item.key) > -1}">
						<i class="iconfont ft14" :class="item.icon"></i>
						<span class="ft14 ml10">{{item.name}}</span>
					</div>
				</div>
			</a-layout-sider>
			<a-layout-content style="padding: 0px 20px; background: #F7F8FA; min-height: calc(100vh - 100px);">
				<div>
					<router-view></router-view>
				</div>
			</a-layout-content>
		</a-layout>
		
		
	</div>
</template>

<script>
	export default {
		data() {
			return {
				menus: [
					{
						name: '站点设置',
						icon: 'iconshezhi1',
						key: 'setting_index',
						link: '/setting'
					},
					
					{
						name: '存储设置',
						icon: 'iconcunchu',
						key: 'setting_oss',
						link: '/setting/oss'
					},
					{
						name: '短信设置',
						icon: 'iconmessage',
						key: 'setting_sms',
						link: '/setting/sms'
					},
					{
						name: '短信签名管理',
						icon: 'iconmessage',
						key: 'setting1_sms',
						link: '/setting/sms1'
					},
					{
						name: '支付设置',
						icon: 'iconzhifushezhi',
						key: 'setting_payment',
						link: '/setting/payment'
					},
					{
						name: '开放平台设置',
						icon: 'iconkaifangpingtai',
						key: 'setting_open',
						link: '/setting/open'
					},
					{
						name: '充值套餐设置',
						icon: 'iconmoban',
						key: 'setting_package',
						link: '/setting/package'
					},
					{
						name: '激活码',
						icon: 'icontabbar05',
						key: 'setting_code',
						link: '/setting/code'
					},
					{
						name: '商城提现管理',
						icon: 'iconshezhi1',
						key: 'setting_tixian',
						link: '/setting/tixian'
					},
					{
						name: '自定义SQL报表',
						icon: 'icontop_nav03',
						key: 'setting_forms',
						link: '/setting/forms'
					}
				],
			}
		},
		methods: {
			goToUrl(index) {
				this.refreshPage(this.menus[index].link);
			},
			addAct(){
				this.$router.push('/application/edit');
			},
		}
	}
</script>

<style>
	.merchant-layout-audit-num{
		background: #FF6600;
		border-radius: 8px;
		padding: 0px 5px 0px 5px;
		
		font-size: 12px;
		font-weight: 400;
		color: #FFFFFF;
	}
</style>
